















import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CategoryModelTab',

  props: {
    id: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },

    tabPanelId: {
      type: String,
      required: true,
    },
  },

  emits: ['select'],

  setup(props, context) {
    const computedClasses = computed(() => [
      'cursor-pointer text-center px-2 py-3 rounded-t-lg font-bold',
      'focus:outline-none',
      'text-xxs md:text-lg lg:text-xl',
      ...(props.isSelected
        ? ['bg-green-custom text-white']
        : ['bg-white text-green-custom']),
    ])

    function onClick() {
      context.emit('select', props.id)
    }

    return {
      computedClasses,
      onClick,
    }
  },
})
