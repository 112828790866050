import { TypologyModel, typologyService } from '@/services/modules/typology'
import { currentLanguage } from '@/utils/language'
import { computed, ComputedRef, isRef } from '@vue/composition-api'
// import { AxiosRequestConfig } from 'axios'
import { useServicePaginated } from './servicePaginated'

export function useTypologies(
  model: ComputedRef<TypologyModel> | TypologyModel
) {
  const request = computed<TypologyModel>(() => ({
    languageId: currentLanguage.value.guid,
    ...(isRef(model) ? model.value : model),
  }))
  return useServicePaginated(request, () => typologyService.get(request.value))
}
