












import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CategoryModelTabPanel',

  props: {
    id: {
      type: String,
      required: true,
    },

    tabId: {
      type: String,
      required: true,
    },

    isHidden: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const computedClasses = computed(() => [
      ...(!props.isHidden
        ? ['bg-gray-50']
        : ['bg-gray-50 text-gray-600 dark:bg-opacity-10']),
    ])

    return {
      computedClasses,
    }
  },
})
