































































































import SiteSection from '@/components/SiteSection.vue'
import CategoryModelTab from '@/components/CategoryModelTab.vue'
import CategoryModelTabPanel from '@/components/CategoryModelTabPanel.vue'
import ModelPanelContent from '@/components/ModelPanelContent.vue'
import {
  // getProjectsTabListConfig,
  // ProjectCategoryTabPanel,
  getModelsTabListConfig,
} from '@/utils/costessa'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'CostessaModels',

  components: {
    SiteSection,
    CategoryModelTab,
    CategoryModelTabPanel,
    ModelPanelContent,
  },

  setup() {
    const { tabs, tabPanels } = getModelsTabListConfig()

    const selectedTabId = ref(tabs[0].id)

    const selectPreviousTab = () => {
      const selectedTabIndex = tabs.findIndex(
        (tab) => tab.id === selectedTabId.value
      )

      if (selectedTabIndex === -1) return

      selectedTabId.value =
        tabs[(selectedTabIndex === 0 ? tabs.length : selectedTabIndex) - 1].id
    }

    const selectNextTab = () => {
      const selectedTabIndex = tabs.findIndex(
        (tab) => tab.id === selectedTabId.value
      )

      if (selectedTabIndex === -1) return

      selectedTabId.value =
        tabs[selectedTabIndex === tabs.length - 1 ? 0 : selectedTabIndex + 1].id
    }

    const onTabKeyboardEvent = (event: KeyboardEvent) => {
      const key = event.key
      if (key === 'ArrowLeft') {
        selectPreviousTab()
      } else if (key === 'ArrowRight') {
        selectNextTab()
      }
    }

    return {
      tabs,
      tabPanels,
      selectedTabId,
      onTabKeyboardEvent,
    }
  },
})
