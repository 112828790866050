import { AxiosInstance } from 'axios'
import { api } from '../client'
import {
  Audit,
  serviceUrl,
  PaginationSelectModel,
  PaginationList,
} from '../utils'

const controller = serviceUrl('typology')

function createTypologyService(client: AxiosInstance) {
  return {
    get(params: TypologyModel) {
      return client.get<PaginationList<TypologyViewModel>>(
        controller.action('get'),
        {
          params,
        }
      )
    },

    update(model: TypologyUpdateModel) {
      return client.post<boolean>(controller.action('update'), model)
    },
  }
}

export const typologyService = createTypologyService(api.base)

export interface TypologyModel extends PaginationSelectModel {
  languageId?: string
  typologyId?: string
}

export interface TypologyUpdateModel {
  typologyTableType: TypologyTableType[]
}

export interface TypologyViewModel extends Audit {
  typologyId: string
  typologyKey: number
  price: number
  code: string
  description: string
  isDeleted: boolean
  displayOrder: number
  isSoldOut: boolean
}

export interface TypologyTableType {
  typologyId: string
  price: number
  isSoldOut: boolean
}
