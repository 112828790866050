






















































































































































































































































































































































































import SiteSection from '@/components/SiteSection.vue'
import NumberAnimated from '@/components/number/NumberAnimated.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { defineComponent, computed, ref } from '@vue/composition-api'

const imagesCover = [
  {
    id: 1,
    src: '@/assets/images/costessa/cover/entrada_lobby.jpg',
    alt: 'Lobby',
  },
  {
    id: 2,
    src: '@/assets/images/costessa/cover/gym.jpg',
    alt: 'Gym',
  },
  {
    id: 3,
    src: '@/assets/images/costessa/cover/MPHA_terraza.jpg',
    alt: 'Terraza',
  },
  {
    id: 4,
    src: '@/assets/images/costessa/cover/MPHB_sala_comedor.jpg',
    alt: 'Lobby',
  },
  {
    id: 5,
    src: '@/assets/images/costessa/cover/pool1.jpg',
    alt: 'Alberca',
  },
  {
    id: 6,
    src: '@/assets/images/costessa/cover/zona_camastros.jpg',
    alt: 'Camastros',
  },
]

export default defineComponent({
  name: 'CostessaHero',

  components: {
    SiteSection,
    Swiper,
    SwiperSlide,
    NumberAnimated,
  },

  setup() {
    const swiper = ref<InstanceType<typeof Swiper> | null>(null)

    const swiperOptions = computed(() => ({
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      navigation: false,
    }))

    return {
      swiper,
      swiperOptions,
      imagesCover,
    }
  },
})
