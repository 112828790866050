






























































































































































import SiteSection from '@/components/SiteSection.vue'
import ContactForm from '@/components/ContactForm.vue'
import BrandLogo from '@/components/branding/BrandLogo.vue'
import { currentLanguage } from '@/utils/language'
import { formatPhoneNumber } from '@/utils/phone'
import { useAgents } from '@/composition/agents'
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'CostessaContact',

  components: {
    SiteSection,
    ContactForm,
    BrandLogo,
  },

  setup() {
    const { data: agents, isLoading: isLoadingAgents } = useAgents({
      agentKey: 19,
    })

    const agent = computed(() => agents.value[0])

    const agentPhone = computed(() =>
      formatPhoneNumber(agent.value.phone || '')
    )

    const agentCellPhone = computed(() =>
      formatPhoneNumber(agent.value.cellPhone)
    )

    const certifiedLogo = computed(() =>
      currentLanguage.value.locale === 'en-US' ? 'certified' : 'certificada'
    )

    return {
      agent,
      isLoadingAgents,
      agentCellPhone,
      agentPhone,
      currentLanguage,
      certifiedLogo,
    }
  },
})
