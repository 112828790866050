













import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ModelTab',

  props: {
    id: {
      type: Number,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['select'],

  setup(props, context) {
    const computedClasses = computed(() => [
      'cursor-pointer text-center p-2 h-14 w-14 xl:h-20 xl:w-20 rounded-lg font-bold border-2',
      'flex justify-center items-center',
      'focus:outline-none',
      'text-xs md:text-sm lg:text-md xl:text-lg border-green-custom',
      ...(props.isSelected
        ? ['bg-green-custom text-white']
        : ['bg-white text-green-custom']),
    ])

    function onClick() {
      context.emit('select', props.id)
    }

    return {
      computedClasses,
      onClick,
    }
  },
})
