








































































































































































































import SiteSection from '@/components/SiteSection.vue'
import { defineComponent, computed, ref } from '@vue/composition-api'

const locationIconSize = 15

const mapOptions = {
  disableDefaultUI: true,
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#ebe3cd',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#523735',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f1e6',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#c9b2a6',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#dcd2be',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#ae9e90',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry',
      stylers: [
        {
          color: '#fdf2eb',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#fdf2eb',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#93817c',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#909b8e',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#447530',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#fdfcf8',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#d3ab44',
        },
      ],
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e98d58',
        },
      ],
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#db8555',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#806b63',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dfd2ae',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#8f7d77',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#ebe3cd',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dfd2ae',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#abc2d9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#92998d',
        },
      ],
    },
  ],
}

const locationsMarkersOptions: google.maps.MarkerOptions[] = [
  {
    position: {
      lat: 27.937185235888048,
      lng: -111.09821323846775,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_1.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.94504705058884,
      lng: -111.09478374730611,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_2.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.943332508649966,
      lng: -111.07826000353816,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_3.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.948155699794082,
      lng: -111.05532272010227,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_4.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.93053116506457,
      lng: -110.97044344235906,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_5.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.95981629499092,
      lng: -110.96889419950901,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_6.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.96799880737178,
      lng: -111.02155582729428,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_7.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.960756207606092,
      lng: -111.03314568960751,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_8.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.951468352260754,
      lng: -111.05188038573384,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_9.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.960368965368,
      lng: -111.0342563000898,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_10.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.948970776466453,
      lng: -111.05551314328058,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_11.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.948625487692016,
      lng: -111.05380447141765,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_12.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.95753406538397,
      lng: -111.04237380009278,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_13.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.959380512055557,
      lng: -111.03756707161536,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_14.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.9625340727518,
      lng: -111.02574861785027,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_15.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.962768588928345,
      lng: -111.02632467142524,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_16.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.96322527016773,
      lng: -111.0247441704446,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_17.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.962985589830364,
      lng: -111.02370457685188,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_18.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.963573389112348,
      lng: -111.02051239921036,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_19.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.96567618918203,
      lng: -111.0136987714266,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_20.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
  {
    position: {
      lat: 27.96785545030258,
      lng: -111.00773652062755,
    },
    icon: {
      url: require(`../assets/images/costessa/map/cost_icon_21.svg`),
      scaledSize: {
        width: locationIconSize,
        height: locationIconSize,
      } as google.maps.Size,
    },
  },
]

export default defineComponent({
  name: 'CostessaLocation',

  components: {
    SiteSection,
  },

  setup() {
    const centerCoords = computed(() => ({
      lat: 27.95603129947652,
      lng: -111.03282839273128,
    }))

    const companyPosition = computed(() => ({
      lat: 27.96416904078437,
      lng: -111.0145760989521,
    }))

    const map = ref<google.maps.Map | null>(null)

    const mapZoom = ref(12)

    const companyMarkerOptions = computed<google.maps.MarkerOptions>(() => ({
      position: companyPosition.value,
      icon: {
        url: require(`../assets/images/costessa/map/cost_icon_pointer.svg`),
        scaledSize: { width: 50, height: 50 } as google.maps.Size,
      },
      clickable: true,
    }))

    function zoomCostessa(e: any) {
      if (map.value) {
        map.value.panTo(e.latLng)
        mapZoom.value = 16
      }
    }

    return {
      map,
      centerCoords,
      mapZoom,
      companyMarkerOptions,
      mapOptions,
      locationsMarkersOptions,
      zoomCostessa,
    }
  },
})
