



















































































import SiteSection from '@/components/SiteSection.vue'
import { defineComponent } from '@vue/composition-api'
import { getCostessaPhotoTourRouteTo } from '@/utils/costessa'

export default defineComponent({
  name: 'CostessaGallery',

  components: {
    SiteSection,
  },

  setup() {
    return {
      getCostessaPhotoTourRouteTo,
    }
  },
})
