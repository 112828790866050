












































































































































































































































































































































































































import { useCurrency } from '@/composition/currency'
import { useTypologies } from '@/composition/typologies'
import ModelTab from '@/components/ModelTab.vue'
import { ModelCategoryTabPanelModelItem } from '@/utils/costessa'
import { Swiper } from 'vue-awesome-swiper'
import i18n from '@/setup/i18n'
import {
  defineComponent,
  PropType,
  ref,
  computed,
  reactive,
} from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'ModelPanelContent',

  components: {
    ModelTab,
  },

  props: {
    models: {
      type: Array as PropType<ModelCategoryTabPanelModelItem[]>,
      required: true,
    },
  },

  setup(props) {
    const swiper = ref<InstanceType<typeof Swiper> | null>(null)

    const swiperOptions = reactive({
      observer: true,
      observeParents: true,
      initialSlide: 0,
      slidesPerView: 1,
      spaceBetween: 40,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })

    const { data: condosPrices, isLoading } = useTypologies({
      pageNumber: 1,
      rowsPerPage: -1,
    })

    const firstFloorLabel = computed(() => i18n.t('firstFloor'))
    const funcionalityLabel = computed(() => i18n.t('funcionality'))
    const footageLabel = computed(() => i18n.t('footage'))
    const locationLabel = computed(() => i18n.t('location'))
    const viewFromTheSeaLabel = computed(() => i18n.t('viewFromTheSea'))
    const beachLabel = computed(() => i18n.t('beach'))

    const selectedModelId = ref(props.models[0].id)

    const selectedModelIndex = ref(0)
    const selectedModel = computed(() => {
      swiper.value?.$swiper.slideTo(0)
      return props.models[selectedModelIndex.value]
    })

    const idModelLabel = computed(() => i18n.t(selectedModel.value.id))
    const titleModelLabel = computed(() => i18n.t(selectedModel.value.title))

    function findCondoPriceByCode(code: TranslateResult) {
      return condosPrices.value.find((condo) => condo.code === code)?.price
    }

    const condoPrice = computed(
      () =>
        useCurrency({ value: findCondoPriceByCode(idModelLabel.value) || 0 })
          .value
    )

    const isCondoSoldOut = computed(
      () =>
        condosPrices.value.find((condo) => condo.code === idModelLabel.value)
          ?.isSoldOut
    )

    const funcFloorBedroomsLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.floor.bedrooms)
    )
    const funcFloorBathroomsLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.floor.bathrooms)
    )
    const funcFloorClosetLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.floor.closet)
    )
    const funcFloorKitchenLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.floor.kitchen)
    )
    const funcFloorLivingRoomLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.floor.livingRoom)
    )
    const funcFloorStayStudyLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.floor.stayStudy)
    )
    const funcFloorDinningRoomLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.floor.dinningRoom)
    )
    const funcFloorLaundryLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.floor.laundry)
    )
    const funcFloorTerraceLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.floor.terrace)
    )

    const funcRoofBedroomsLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.roof.bedrooms)
    )
    const funcRoofBathroomsLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.roof.bathrooms)
    )
    const funcRoofAreaOneLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.roof.areaOne)
    )
    const funcRoofAreaTwoLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.roof.areaTwo)
    )
    const funcRoofAreaThreeLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.roof.areaThree)
    )
    const funcRoofPoolLabel = computed(() =>
      i18n.t(selectedModel.value.funcionality.roof.pool)
    )

    const footageFloorInsideLabel = computed(() =>
      i18n.t(selectedModel.value.footage.floor.inside)
    )
    const footageFloorTerraceLabel = computed(() =>
      i18n.t(selectedModel.value.footage.floor.terrace)
    )

    const footageRoofInsideLabel = computed(() =>
      i18n.t(selectedModel.value.footage.roof.inside)
    )
    const footageRoofTerraceLabel = computed(() =>
      i18n.t(selectedModel.value.footage.roof.terrace)
    )

    const footageTotalLabel = computed(() =>
      i18n.t(selectedModel.value.footage.total)
    )

    const locationFloorLevelsLabel = computed(() =>
      i18n.t(selectedModel.value.location.floor.levels)
    )

    const locationRoofLevelsLabel = computed(() =>
      i18n.t(selectedModel.value.location.roof.levels)
    )

    const imageViewOne = computed(() => {
      console.log(selectedModel.value.images.view[0].imageSrc)
      return selectedModel.value.images.view[0].imageSrc
    })

    const imageViewTwo = computed(
      () => selectedModel.value.images.view[1].imageSrc
    )

    const funcRoofIsEmpty = () => {
      let arr = [
        funcRoofBedroomsLabel.value,
        funcRoofBathroomsLabel.value,
        funcRoofAreaOneLabel.value,
        funcRoofAreaTwoLabel.value,
        funcRoofAreaThreeLabel.value,
        funcRoofPoolLabel.value,
      ]

      return arr.every((x) => x === '')
    }

    const footageRoofIsEmpty = () => {
      let arr = [footageRoofInsideLabel.value, footageRoofTerraceLabel.value]

      return arr.every((x) => x === '')
    }

    const funcFloorList = () => {
      let arr = [
        funcFloorBedroomsLabel.value,
        funcFloorBathroomsLabel.value,
        funcFloorClosetLabel.value,
        funcFloorKitchenLabel.value,
        funcFloorLivingRoomLabel.value,
        funcFloorStayStudyLabel.value,
        funcFloorDinningRoomLabel.value,
        funcFloorLaundryLabel.value,
        funcFloorTerraceLabel.value,
      ]

      return arr.filter((x) => x !== '')
    }

    const funcRoofList = () => {
      let arr = [
        funcRoofBedroomsLabel.value,
        funcRoofBathroomsLabel.value,
        funcRoofAreaOneLabel.value,
        funcRoofAreaTwoLabel.value,
        funcRoofAreaThreeLabel.value,
        funcRoofPoolLabel.value,
      ]

      return arr.filter((x) => x !== '')
    }

    const footageFloorList = () => {
      let arr = [footageFloorInsideLabel.value, footageFloorTerraceLabel.value]

      return arr.filter((x) => x !== '')
    }

    const footageRoofList = () => {
      let arr = [footageRoofInsideLabel.value, footageRoofTerraceLabel.value]

      return arr.filter((x) => x !== '')
    }

    return {
      swiper,
      swiperOptions,
      selectedModelId,
      selectedModelIndex,
      selectedModel,
      firstFloorLabel,
      funcionalityLabel,
      footageLabel,
      locationLabel,
      viewFromTheSeaLabel,
      beachLabel,
      idModelLabel,
      titleModelLabel,
      funcFloorBedroomsLabel,
      funcFloorBathroomsLabel,
      funcFloorClosetLabel,
      funcFloorKitchenLabel,
      funcFloorLivingRoomLabel,
      funcFloorStayStudyLabel,
      funcFloorDinningRoomLabel,
      funcFloorLaundryLabel,
      funcFloorTerraceLabel,
      funcRoofBedroomsLabel,
      funcRoofBathroomsLabel,
      funcRoofAreaOneLabel,
      funcRoofAreaTwoLabel,
      funcRoofAreaThreeLabel,
      funcRoofPoolLabel,
      footageFloorInsideLabel,
      footageFloorTerraceLabel,
      footageRoofInsideLabel,
      footageRoofTerraceLabel,
      footageTotalLabel,
      locationFloorLevelsLabel,
      locationRoofLevelsLabel,
      imageViewOne,
      imageViewTwo,
      funcRoofIsEmpty,
      footageRoofIsEmpty,
      funcFloorList,
      funcRoofList,
      footageFloorList,
      footageRoofList,
      isLoading,
      condoPrice,
      isCondoSoldOut,
    }
  },
})
