












import CostessaHero from '@/components/CostessaHero.vue'
import CostessaLocation from '@/components/CostessaLocation.vue'
import CostessaAmenities from '@/components/CostessaAmenities.vue'
import CostessaModels from '@/components/CostessaModels.vue'
import CostessaGallery from '@/components/CostessaGallery.vue'
import CostessaPaymentSchemes from '@/components/CostessaPaymentSchemes.vue'
import CostessaContact from '@/components/CostessaContact.vue'
import { defineComponent, onMounted } from '@vue/composition-api'
import { currentRoute } from '@/router'

export default defineComponent({
  name: 'Costessa',

  components: {
    CostessaHero,
    CostessaLocation,
    CostessaAmenities,
    CostessaModels,
    CostessaGallery,
    CostessaPaymentSchemes,
    CostessaContact,
  },

  setup() {
    onMounted(() => {
      if (currentRoute.value) {
        const sectionEl = document.getElementById(
          currentRoute.value.hash.slice(1)
        )
        if (sectionEl) {
          sectionEl.scrollIntoView()
        }
      }
    })
    return {}
  },
})
